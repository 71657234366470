import { Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { EEnergySourceType } from "../../enums/energy-source-type";
import { energySourceIconType } from "../../utils/energy-source-icon-type";
import { findEnergySourceType } from "../../utils/find-energy-source-type";
import MaterialIcon from "../material-icon/material-icon";
import styles from "./power-energy-source.module.scss";

interface IProps {
  energySourceType: string[];
}

const PowerEnergySource = (props: IProps) => {
  const { energySourceType } = props;
  return (
    <Row
      className={`${styles.dualPowerSourceCardContainer} align-items-center mx-0 p-2 gx-3`}
    >
      {energySourceType.map((item, index) => {
        return (
          <Fragment key={index}>
            <Col xs={"auto"}>
              <Row className="align-items-center g-2">
                <Col xs={"auto"} className="">
                  <MaterialIcon
                    icon={energySourceIconType(item as EEnergySourceType)}
                    color="#1C1B1F"
                  />
                </Col>
                <Col className="text-light font-size-12 font-weight-500">
                  {findEnergySourceType(item as EEnergySourceType)}
                </Col>
              </Row>
            </Col>
            {index < energySourceType.length - 1 && <Col xs={"auto"}>+</Col>}
          </Fragment>
        );
      })}
    </Row>
  );
};

export default PowerEnergySource;

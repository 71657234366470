import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectAuth } from "../redux/features/auth/auth-slice";
import DashboardLayout from "../shared/components/dashboard-layout/dashboard-layout";
import PublicRoute from "../shared/components/public-route/public-route";
import { EPrivileges } from "../shared/oversight-core/enums/privileges";
import { UserRole } from "../shared/oversight-core/enums/user-roles";
import { AppRoute } from "../shared/oversight-core/interfaces/app-routes";
import IPageInfo from "../shared/oversight-core/interfaces/page-info";
import ComingSoon from "../shared/oversight-core/shared-components/coming-soon/coming-soon";
import NotFound from "../shared/oversight-core/shared-components/not-found/not-found";
import PrivateRoute from "../shared/oversight-core/shared-components/private-route/private-route";
import Unauthorized from "../shared/oversight-core/shared-components/unauthorized/unauthorized";
import ChangeEmail from "../shared/oversight-core/shared-pages/change-email/change-email";
import ChangeMobileNumber from "../shared/oversight-core/shared-pages/change-mobile-number/change-mobile-number";
import ForgotPassword from "../shared/oversight-core/shared-pages/forgot-password/forgot-password";
import MobileNumberOtpVerification from "../shared/oversight-core/shared-pages/mobile-number-otp-verification/mobile-number-otp-verification";
import MobileNumberVerification from "../shared/oversight-core/shared-pages/mobile-number-verification/mobile-number-verification";
import ResetPassword from "../shared/oversight-core/shared-pages/reset-password/reset-password";
import UserProfile from "../shared/oversight-core/shared-pages/user-profile/user-profile";
import RemoveTrailingSlash from "../shared/oversight-core/utils/removeTrailingSlash";
import AddUpdateController from "./dashboard/controllers/components/add-update-controllers/add-update-controllers";
import Controller from "./dashboard/controllers/controller/controller";
import Controllers from "./dashboard/controllers/controllers";
import EnergyUsage from "./dashboard/energy-usage/energy-usage";
import Generation from "./dashboard/generation/generation";
import Home from "./dashboard/home/home";
import AddUpdateSchedule from "./dashboard/schedule/components/add-update-schedule/add-update-schedule";
import Schedule from "./dashboard/schedule/schedule";
import SourceManagement from "./dashboard/source-management/source-management";
import SpaceCluster from "./dashboard/space-clusters/components/space-cluster/space-cluster";
import SpaceClusters from "./dashboard/space-clusters/space-clusters";
import Usage from "./dashboard/usage/usage";
import Login from "./login/login";
import Register from "./register/register";

export const publicRoutes: IPageInfo[] = [
  {
    name: "Login",
    path: AppRoute.LOGIN,
    icon: "home",
    component: <Login />,
    enableRoute: true,
  },
  {
    name: "Register",
    path: AppRoute.REGISTER,
    component: <Register />,
  },
  {
    name: "Forgot Password",
    path: AppRoute.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    enableRoute: true,
  },
  {
    name: "Reset Password",
    path: AppRoute.RESET_PASSWORD,
    component: <ResetPassword />,
    enableRoute: true,
  },
  {
    name: "Mobile Number Verification",
    path: AppRoute.MOBILE_NUMBER_VERIFICATION,
    component: <MobileNumberOtpVerification />,
    enableRoute: true,
  },
];

export const consumerPrivateRoutes: IPageInfo[] = [
  {
    name: "Mobile Number Verification",
    path: AppRoute.USER_MOBILE_NUMBER_VERIFICATION,
    component: <MobileNumberOtpVerification />,
    enableRoute: true,
  },
  {
    name: "Mobile Number OTP Verification",
    path: AppRoute.VERIFY_MOBILE_NUMBER_OTP,
    component: <MobileNumberOtpVerification />,
    showOnDrawer: false,
  },
  {
    name: "Mobile Number Verification",
    path: AppRoute.VERIFY_MOBILE_NUMBER,
    component: <MobileNumberVerification />,
    showOnDrawer: false,
  },
  {
    name: "Change Mobile Number",
    path: AppRoute.CHANGE_MOBILE_NUMBER,
    component: <ChangeMobileNumber />,
    showOnDrawer: false,
  },
  {
    name: "Change Email",
    path: AppRoute.CHANGE_EMAIL,
    component: <ChangeEmail />,
    showOnDrawer: false,
  },
  {
    name: "Change Password",
    path: AppRoute.CHANGE_PASSWORD,
    component: <ResetPassword />,
    enableRoute: true,
  },
  {
    name: "Dashboard",
    path: AppRoute.DASHBOARD,
    component: <DashboardLayout />,
    root: true,
    subRoutes: [
      {
        name: "Dashboard",
        icon: "home",
        path: AppRoute.HOME,
        index: true,
        component: <Home />,
        enableRoute: true,
        bgColor: "activeBg-1",
        bgColorIcon: "#7318aa",
        showOnDrawer: true,
        privilege: EPrivileges.DASHBOARD_READ_PRIVILEGE,
      },
      {
        name: "Devices",
        path: AppRoute.SPACE_CLUSTERS,
        icon: "devices_other",
        component: <SpaceClusters />,
        enableRoute: true,
        bgColor: "activeBg-4",
        bgColorIcon: "#004a74",
        showOnDrawer: true,
        privilege: EPrivileges.DEVICE_READ_PRIVILEGE,
      },
      {
        name: "Devices",
        path: AppRoute.SPACE_CLUSTERS + "/*",
        icon: "devices_other",
        component: <SpaceCluster />,
        enableRoute: true,
        showOnDrawer: false,
        privilege: EPrivileges.DEVICE_READ_PRIVILEGE,
      },
      {
        name: "Controllers",
        path: AppRoute.CONTROLLERS,
        icon: "smart_outlet",
        component: <Controllers />,
        enableRoute: true,
        bgColor: "activeBg-4",
        bgColorIcon: "#30858A",
        showOnDrawer: true,
        privilege: EPrivileges.CONTROLLER_READ_PRIVILEGE,
      },
      {
        name: "Controllers",
        path: AppRoute.CONTROLLERS + "/*",
        icon: "smart_outlet",
        component: <Controller />,
        enableRoute: true,
        showOnDrawer: false,
        privilege: EPrivileges.CONTROLLER_READ_PRIVILEGE,
      },
      {
        name: "Smart Controllers > Add Controller",
        path: AppRoute.ADD_CONTROLLER,
        icon: "smart_outlet",
        component: <AddUpdateController />,
        enableRoute: true,
        showOnDrawer: false,
        privilege: EPrivileges.CONTROLLER_READ_PRIVILEGE,
      },
      {
        name: "Smart Controllers > Edit Controller",
        path: AppRoute.EDIT_CONTROLLER,
        icon: "smart_outlet",
        component: <AddUpdateController />,
        enableRoute: true,
        showOnDrawer: false,
        privilege: EPrivileges.CONTROLLER_READ_PRIVILEGE,
      },
      {
        name: "Schedule",
        path: AppRoute.SCHEDULE,
        icon: "autoplay",
        component: <Schedule />,
        enableRoute: true,
        bgColor: "activeBg-10",
        bgColorIcon: "#6B43C0",
        showOnDrawer: true,
        privilege: EPrivileges.SCHEDULE_READ_PRIVILEGE,
      },
      {
        name: "Add Schedule",
        path: AppRoute.ADD_SCHEDULE,
        icon: "schedule",
        component: <AddUpdateSchedule />,
        enableRoute: true,
        bgColor: "activeBg-10",
        bgColorIcon: "#6B43C0",
        showOnDrawer: false,
        privilege: EPrivileges.SCHEDULE_READ_PRIVILEGE,
      },
      {
        name: "Edit Schedule",
        path: AppRoute.EDIT_SCHEDULE,
        icon: "schedule",
        component: <AddUpdateSchedule />,
        enableRoute: true,
        bgColor: "activeBg-10",
        bgColorIcon: "#6B43C0",
        showOnDrawer: false,
        privilege: EPrivileges.SCHEDULE_READ_PRIVILEGE,
      },
      {
        name: "Generation",
        path: AppRoute.GENERATION,
        icon: "sunny",
        component: <Generation />,
        enableRoute: true,
        bgColor: "activeBg-13",
        bgColorIcon: "#AD792B",
        showOnDrawer: true,
        privilege: EPrivileges.GENERATION_READ_PRIVILEGE,
      },
      {
        name: "Usage",
        path: AppRoute.USAGE,
        icon: "electric_meter",
        component: <Usage />,
        enableRoute: true,
        bgColor: "activeBg-8",
        bgColorIcon: "#aa1821",
        showOnDrawer: true,
        privilege: EPrivileges.USAGE_GUIDE_READ_PRIVILEGE,
      },
      {
        name: "Usage > Energy Usage",
        path: AppRoute.ENERGY_USAGE,
        icon: "electric_meter",
        component: <EnergyUsage />,
        enableRoute: true,
        bgColor: "activeBg-3",
        bgColorIcon: "#aa1821",
        showOnDrawer: false,
        privilege: EPrivileges.USAGE_GUIDE_READ_PRIVILEGE,
      },
      // {
      //   name: "Usage Guide",
      //   path: AppRoute.LIMITATION_USAGE_GUIDE,
      //   icon: "history_toggle_off",
      //   component: <UsageGuide />,
      //   enableRoute: true,
      //   bgColor: "activeBg-11",
      //   bgColorIcon: "#725080",
      //   showOnDrawer: true,
      // },
      // {
      //   name: "Usage Guide",
      //   path: AppRoute.JOIN_DEMAND_RESPONSE_PROGRAM,
      //   icon: "history_toggle_off",
      //   component: <JoinDemandResponseProgram />,
      //   enableRoute: true,
      //   bgColor: "activeBg-11",
      //   bgColorIcon: "#725080",
      //   showOnDrawer: false,
      // },
      // {
      //   name: "Usage Guide",
      //   path: AppRoute.EDIT_DEMAND_RESPONSE_PROGRAM,
      //   icon: "history_toggle_off",
      //   component: <JoinDemandResponseProgram />,
      //   enableRoute: true,
      //   bgColor: "activeBg-11",
      //   bgColorIcon: "#725080",
      //   showOnDrawer: false,
      // },
      // {
      //   name: "Usage Guide",
      //   path: AppRoute.VIEW_DEMAND_RESPONSE_PROGRAM,
      //   icon: "history_toggle_off",
      //   component: <ViewDemandResponseProgram />,
      //   enableRoute: true,
      //   bgColor: "activeBg-11",
      //   bgColorIcon: "#725080",
      //   showOnDrawer: false,
      // },
      {
        name: "Manage",
        path: AppRoute.MANAGE,
        icon: "tune",
        component: <SourceManagement />,
        enableRoute: true,
        bgColor: "activeBg-14",
        bgColorIcon: "#1C1B1F",
        showOnDrawer: true,
      },
      {
        name: "Settings",
        path: AppRoute.SETTINGS,
        icon: "settings",
        component: <ComingSoon />,
        enableRoute: true,
        bgColor: "activeBg-6",
        bgColorIcon: "#383941",
        showOnDrawer: true,
      },
      // {
      //   name: "Bill Calculator",
      //   path: AppRoute.BILL_CALCULATOR,
      //   icon: "calculate",
      //   component: <BillCalculator />,
      //   enableRoute: true,
      //   bgColor: "activeBg-6",
      //   bgColorIcon: "#2F2A89",
      //   showOnDrawer: true,
      // },
      {
        name: "Profile",
        path: AppRoute.USER_PROFILE,
        component: <UserProfile />,
        enableRoute: true,
        showOnDrawer: false,
      },
      {
        name: "404 Page Not Found",
        path: AppRoute.NOT_FOUND,
        component: <NotFound />,
        showOnDrawer: false,
      },
    ],
  },
];

const AppRoutes = () => {
  const auth = useSelector(selectAuth);
  const getRoutes = (routes: IPageInfo[]) => {
    return routes.map((privateRoutes: IPageInfo, index) => (
      <Route
        key={index}
        path={privateRoutes.path}
        element={privateRoutes.component}
      >
        {privateRoutes.subRoutes?.map((subRoute: IPageInfo, index) => {
          const route = [
            <Route
              key={index}
              path={subRoute.path}
              element={
                !subRoute.privilege ||
                auth.privileges.includes(subRoute.privilege) ? (
                  subRoute.component
                ) : (
                  <Unauthorized />
                )
              }
            />,
          ];

          if (subRoute.index) {
            route.push(
              <Route
                key={index + subRoute.path}
                index
                element={<Navigate to={subRoute.path} />}
              />
            );
          }
          return route;
        })}
      </Route>
    ));
  };

  return (
    <Fragment>
      <RemoveTrailingSlash />
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route index element={<Navigate to={AppRoute.LOGIN} />} />
          {publicRoutes.map((p: IPageInfo, index) => {
            return <Route key={index} path={p.path} element={p.component} />;
          })}
          <Route path="*" element={<Navigate to={AppRoute.LOGIN} />} />
        </Route>

        <Route path="/" element={<PrivateRoute role={UserRole.ROLE_USER} />}>
          {getRoutes(consumerPrivateRoutes)}
        </Route>

        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </Fragment>
  );
};

export default AppRoutes;

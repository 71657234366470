/* eslint-disable react/no-unknown-property */
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef, useState } from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";
import { IProps } from "../../mini-grid";
import AnimatedShaderMaterial from "../animated-shader-material/animated-shader-material";
import EmissiveMaterial from "../emissive-material/emissive-material";
import Label from "../label/label";

type GLTFResult = GLTF & {
  nodes: {
    Sphere: THREE.Mesh;
    Plane002: THREE.Mesh;
    house: THREE.Mesh;
    ["gound-controllerr_1"]: THREE.Mesh;
    ["gound-controllerr_2"]: THREE.Mesh;
    ["gound-controller-left_1"]: THREE.Mesh;
    ["gound-controller-left_2"]: THREE.Mesh;
    fan: THREE.Mesh;
    Cylinder002: THREE.Mesh;
    Cylinder001: THREE.Mesh;
    Cube005: THREE.Mesh;
    Cube004: THREE.Mesh;
    Cube003: THREE.Mesh;
    Cube002: THREE.Mesh;
    Cube001: THREE.Mesh;
    building: THREE.Mesh;
    Plane001: THREE.Mesh;
    Cube006: THREE.Mesh;
    Plane003: THREE.Mesh;
    Cube007: THREE.Mesh;
    ["wire-solar"]: THREE.Mesh;
    ["wire-wind"]: THREE.Mesh;
    ["wire-genarator"]: THREE.Mesh;
    ["wire-grid"]: THREE.Mesh;
    ["wire-to-battery"]: THREE.Mesh;
  };
  materials: {
    ["red-bulb"]: THREE.MeshStandardMaterial;
    solar: THREE.MeshStandardMaterial;
    wall: THREE.MeshStandardMaterial;
    matal: THREE.MeshStandardMaterial;
    ["controll-box"]: THREE.MeshStandardMaterial;
    wave: THREE.MeshStandardMaterial;
  };
};
type GLTFResult2 = GLTF & {
  nodes: {
    gen_1: THREE.Mesh;
    gen_2: THREE.Mesh;
    Cube008: THREE.Mesh;
    Plane: THREE.Mesh;
    Plane005: THREE.Mesh;
    Plane005_1: THREE.Mesh;
    Cube009: THREE.Mesh;
    Plane006: THREE.Mesh;
    Plane006_1: THREE.Mesh;
    Plane007: THREE.Mesh;
    Plane007_1: THREE.Mesh;
  };
  materials: {
    matal: THREE.MeshStandardMaterial;
    ["red-bulb"]: THREE.MeshStandardMaterial;
    ["controll-box.001"]: THREE.MeshStandardMaterial;
    ["Material.002"]: THREE.MeshStandardMaterial;
    wall: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
    ["controll-box"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"] & IProps) {
  const { nodes, materials } = useGLTF("/modal.glb") as GLTFResult;
  const { nodes: nodes2, materials: materials2 } = useGLTF(
    "/new-modal.glb"
  ) as GLTFResult2;
  const meshRef = useRef<THREE.Mesh>(null); // Reference to the mesh
  const [rotationSpeed, setRotationSpeed] = useState(0);
  useFrame(() => {
    if (meshRef.current) {
      if (props.wind > 0) {
        setRotationSpeed((speed) => Math.min(speed + 0.001, 0.1));
      } else {
        setRotationSpeed((speed) => Math.max(speed * 0.95, 0)); // Gradually reduce speed when wind is false
      }

      meshRef.current.rotation.y += rotationSpeed; // Apply rotation based on speed
    }
  });
  return (
    <group {...props} dispose={null}>
      <group position={[-3.207, 6.937, 6.557]} scale={[0.97, 0.5, 0.5]}>
        <Label
          icon="oven_gen"
          title="Generator"
          value={`${props.generator} kW`}
          endPoint={[0, 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes2.gen_1.geometry}
          material={materials2.matal}
        />

        <mesh castShadow receiveShadow geometry={nodes2.gen_2.geometry}>
          {props.generator > 0 ? (
            <EmissiveMaterial />
          ) : (
            <meshStandardMaterial
              attach="material"
              color={new THREE.Color("#ccc")}
              emissive={new THREE.Color(0x000000)}
            />
          )}
        </mesh>
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes2.Cube008.geometry}
        material={materials2["controll-box.001"]}
        position={[-4.938, 8.783, -2.772]}
        rotation={[0, 1.571, 0]} //1.571
      >
        <Label
          icon={props.gridUsage < 0 ? "arrow_upward" : "charger"}
          title={props.gridUsage < 0 ? "Export to grid" : "Usage from grid"}
          value={(props.gridUsage || 0) + " kW"}
          width={150}
          startPoint={[0 - 0.15, 0, 0 - 0.07]}
          endPoint={[0 - 0.15 + 0, 1.5, 0 - 0.07]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes2.Plane.geometry}
        position={[-2.949, 7.684, 1.465]}
      >
        <AnimatedShaderMaterial
          baseColor1={"#ccc"}
          baseColor2={"#333"}
          animationDirection={props.girdDirection === "Usage" ? 1 : -1}
          isAnimationOff={props.girdDirection === "Nothing"}
        />
      </mesh>
      <group
        position={[3.894, 7.888, 6.213]}
        rotation={[0.197, 0, 0]}
        scale={[2.345, 0.707, 1.186]}
      >
        <mesh castShadow receiveShadow geometry={nodes2.Plane005.geometry}>
          <meshStandardMaterial
            attach="material"
            color={new THREE.Color("#fff")}
            emissive={new THREE.Color(0x000000)}
            roughness={0}
            metalness={0.1}
          />
        </mesh>
        <mesh castShadow receiveShadow geometry={nodes2.Plane005_1.geometry}>
          <meshStandardMaterial
            attach="material"
            color={new THREE.Color("#eee")}
            emissive={new THREE.Color(0x000000)}
            roughness={0}
            metalness={0.1}
          />
        </mesh>
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes2.Cube009.geometry}
        material={materials2["controll-box"]}
        position={[-5.145, 6.646, 2.372]}
        rotation={[0, 1.571, 0]}
      >
        <Label
          icon="electric_meter"
          title="Demand"
          value={`${props.demand} kW`}
        />
      </mesh>
      <group
        position={[3.698, 7.874, 2.793]}
        rotation={[0.197, 0, 0]}
        scale={[2.345, 0.707, 1.186]}
      >
        <Label
          icon="sunny"
          title="Solar Generation"
          value={`${props.solar} kW`}
          width={150}
        />
        <mesh castShadow receiveShadow geometry={nodes2.Plane006.geometry}>
          <meshStandardMaterial
            attach="material"
            color={new THREE.Color("#fff")}
            emissive={new THREE.Color(0x000000)}
            roughness={0}
            metalness={0.1}
          />
        </mesh>
        <mesh castShadow receiveShadow geometry={nodes2.Plane006_1.geometry}>
          <meshStandardMaterial
            attach="material"
            color={new THREE.Color("#eee")}
            emissive={new THREE.Color(0x000000)}
            roughness={0}
            metalness={0.1}
          />
        </mesh>
      </group>
      <group
        position={[3.698, 7.874, -0.823]}
        rotation={[0.197, 0, 0]}
        scale={[2.345, 0.707, 1.186]}
      >
        <mesh castShadow receiveShadow geometry={nodes2.Plane007.geometry}>
          <meshStandardMaterial
            attach="material"
            color={new THREE.Color("#fff")}
            emissive={new THREE.Color(0x000000)}
            roughness={0}
            metalness={0.1}
          />
        </mesh>
        <mesh castShadow receiveShadow geometry={nodes2.Plane007_1.geometry}>
          <meshStandardMaterial
            attach="material"
            color={new THREE.Color("#eee")}
            emissive={new THREE.Color(0x000000)}
            roughness={0}
            metalness={0.1}
          />
        </mesh>
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere.geometry}
        position={[-2.298, 13.404, 4.876]}
      >
        <Label
          icon="energy"
          title="Wind Generation"
          value={`${props.wind} kW`}
          width={150}
          distanceFactor={2}
          startPoint={[0, 0 - 3, 0]}
          endPoint={[-2, 0 - 3, 0]}
        />
        {props.wind > 0 ? (
          <EmissiveMaterial />
        ) : (
          <meshStandardMaterial
            attach="material"
            color={new THREE.Color("#ccc")}
            emissive={new THREE.Color(0x000000)}
          />
        )}
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.house.geometry}
        material={materials.wall}
        position={[-2.733, 8.399, -1.438]}
      ></mesh>
      <group position={[1.173, 6.747, 4.518]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["gound-controllerr_1"].geometry}
        >
          {props.solar > 0 ? (
            <EmissiveMaterial />
          ) : (
            <meshStandardMaterial
              attach="material"
              color={new THREE.Color("#ccc")}
              emissive={new THREE.Color(0x000000)}
            />
          )}
        </mesh>
        <mesh
          geometry={nodes["gound-controllerr_2"].geometry}
          material={materials.matal}
          castShadow
          receiveShadow
        />
      </group>
      <group position={[-3.769, 6.617, 6.318]}>
        <mesh
          geometry={nodes["gound-controller-left_2"].geometry}
          material={materials.matal}
          castShadow
          receiveShadow
        />
      </group>
      <mesh
        ref={meshRef}
        geometry={nodes.fan.geometry}
        material={materials.wall}
        position={[-2.298, 12.075, 4.876]}
        castShadow
        receiveShadow
      ></mesh>
      <mesh
        geometry={nodes.Cylinder002.geometry}
        material={materials.matal}
        position={[-2.298, 10.338, 4.876]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Cylinder001.geometry}
        material={materials.matal}
        position={[-2.298, 10.586, 4.876]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Cube005.geometry}
        material={materials.matal}
        position={[3.772, 7.365, 6.212]}
        castShadow
        receiveShadow
      />
      <group position={[-3.917, 7.619, 0.191]}>
        <mesh
          geometry={nodes.Cube004.geometry}
          material={materials["controll-box"]}
          position={[0, 0, 0]}
          castShadow
          receiveShadow
        >
          <Label
            icon="battery_charging_80"
            title="Battery"
            value={`${props.battery}%`}
          />
        </mesh>
      </group>
      <mesh
        geometry={nodes.Cube003.geometry}
        material={materials["controll-box"]}
        position={[-2.952, 7.297, 0.191]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Cube002.geometry}
        material={materials.matal}
        position={[-2.298, 6.429, 4.876]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Cube001.geometry}
        material={materials["controll-box"]}
        position={[-2.128, 7.942, 0.191]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.building.geometry}
        material={materials.wall}
        position={[0.174, 4.514, 3.489]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Cube006.geometry}
        material={materials.matal}
        position={[3.772, 7.365, 2.775]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Cube007.geometry}
        material={materials.matal}
        position={[3.772, 7.365, -0.855]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes["wire-solar"].geometry}
        position={[-3.016, 6.437, 1.195]}
        castShadow
        receiveShadow
      >
        <AnimatedShaderMaterial
          baseColor1={"#41faa7"}
          baseColor2={"#333"}
          animationDirection={-1}
          isAnimationOff={!(props.solar > 0)}
        />
      </mesh>
      <mesh
        geometry={nodes["wire-wind"].geometry}
        position={[-2.388, 7.368, 1.2]}
        castShadow
        receiveShadow
      >
        <AnimatedShaderMaterial
          baseColor1={"#5695fc"}
          emissionStrengthColor1={2.5}
          baseColor2={"#333"}
          isAnimationOff={!(props.wind > 0)}
        />
      </mesh>
      <mesh
        geometry={nodes["wire-genarator"].geometry}
        position={[-3.213, 6.435, 1.248]}
        castShadow
        receiveShadow
      >
        <AnimatedShaderMaterial
          baseColor1={"#ccc"}
          baseColor2={"#333"}
          animationDirection={-1}
          isAnimationOff={!(props.generator > 0)}
        />
      </mesh>
      <mesh
        geometry={nodes["wire-grid"].geometry}
        material={materials.wave}
        position={[-3.414, 6.435, 1.248]}
        castShadow
        receiveShadow
      >
        <AnimatedShaderMaterial
          baseColor1={"Coral"}
          emissionStrengthColor1={2.5}
          baseColor2={"#333"}
          animationDirection={1}
          isAnimationOff={
            !(
              props.wind > 0 ||
              props.solar > 0 ||
              props.generator > 0 ||
              props.girdDirection === "Usage"
            )
          }
        />
      </mesh>
      <mesh
        geometry={nodes["wire-to-battery"].geometry}
        material={materials.wave}
        position={[-3.316, 7.562, 0.302]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.386}
        castShadow
        receiveShadow
      >
        <AnimatedShaderMaterial
          baseColor1={"#41faa7"}
          baseColor2={"#333"}
          animationDirection={-1}
          isAnimationOff={
            !(props.solar > 0 || props.wind > 0 || props.generator > 0)
          }
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/modal.glb");
useGLTF.preload("/new-modal.glb");
